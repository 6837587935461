@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  background-color: black;
  color: white;
  margin: 0;
  font-family: owners-text, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p a {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@layer base {
  @font-face {
    font-family: 'PepsiOwners-Extended';
    font-style: normal;
    src: local('PepsiOwners-Extended'), url(/public/fonts/PepsiOwners-Extended.ttf) format('truetype');
  }
  @font-face {
    font-family: 'PepsiOwners-Regular';
    font-style: normal;
    src: local('PepsiOwners-Regular'), url(/public/fonts/PepsiOwners-Regular.ttf) format('truetype');
  }
}

@layer utilities {
  header {
    @apply fixed w-full h-[100px] lg:h-[90px] px-[19px] text-white z-[999]
  }
  main {
    @apply  pt-[100px] lg:pt-[90px] transition-[blur] duration-700;
  }
  
  .header-1 {
    @apply text-5xl font-pepsiowners-extended text-center text-white;
  }
  .header-1-span {
    @apply text-2xl font-pepsiowners-extended text-center text-white;
  }
  .header-2 {
    @apply text-2xl md:text-4xl font-pepsiowners-extended text-center text-digital-alt-blue;
  }
  .header-3 {
    @apply font-pepsiowners text-center text-2xl ;
  }
  .menu {
    @apply text-2xl font-pepsiowners leading-loose text-center text-white;
  }
  .text-style {
    @apply text-2xl font-pepsiowners text-white;
  }
  .carousel-title {
    @apply text-lg font-pepsiowners text-center text-white;
  }
  .legal {
    @apply font-pepsiowners leading-loose text-center text-white hover:underline ;
  }
  .results {
    @apply text-sm font-pepsiowners text-white;
  }
  .results-more {
    @apply text-sm font-pepsiowners text-pastel-blue;
  }
  .flavor {
    @apply text-xs font-pepsiowners text-center text-white;
  }
  .accordion-open {
    @apply max-h-full overflow-hidden pt-3 px-11 pb-5;
  }
  .accordion-closed {
    @apply max-h-0 overflow-hidden py-0 px-11;
  }
  .pill-btn {
    @apply border-white border-2 py-2 px-8 rounded-full hover:bg-white hover:text-black transition-all ease-in
  }
  .search-close {
    @apply -top-[150vh];
  }
  .search-open {
    @apply top-[0vh];
  }
  .menu-close {
    @apply -left-[150vw] right-[150vw];
  }
  .menu-open {
    @apply -left-[0vw] right-[0vw];
  }
  .main-search-open {
    @apply blur-lg
  }

  .main-search-closed {
    @apply blur-0
  }
  .customer-rel {
    @apply hover:underline;
  }

  footer {
    @apply mt-auto w-full h-[523px] md:h-[395px] lg:h-[158px] lg:px-[19px] text-white lg:bg-electric-blue
  }


}